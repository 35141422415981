import React, { Component } from 'react'
import Layout from '../layout'
import Signup from '../components/Signup/Signup'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class SignupPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Sign Up" />
        <Header />

        <Signup />

        <Footer style={{ backgroundColor: 'white' }} />
      </Layout>
    )
  }
}

export default SignupPage
