import React, { Component } from 'react'
import { Button, Section, Container, Columns, Column } from 'sqrl-design-system'
import SignupForm from '../forms/signup'

class Signup extends Component {
  render() {
    return (
      <Section
        className="signup-page"
        style={{ paddingTop: 100, backgroundColor: 'white' }}
      >
        <Container style={{ maxWidth: 800 }}>
          <Columns>
            <Column>
              <div style={{ maxWidth: 312 }}>
                <h1 style={{ lineHeight: 1.2, marginBottom: 16 }}>
                  Power up the well-being of your workforce
                </h1>

                <p>
                  We’re excited to partner with you get to know and grow the
                  well-being of your workforce.
                  <br />
                  <br />
                  Tell us a few things about your business and we’ll be in
                  touch!
                </p>
              </div>
            </Column>
            <Column>
              <div>
                <Button className="full-width signup-with-google">
                  <img
                    src="/images/icons/google.svg"
                    style={{ marginRight: 8 }}
                  />{' '}
                  Sign up with Google
                </Button>
                <div className="or">or</div>
                <SignupForm />
              </div>
            </Column>
          </Columns>
        </Container>
      </Section>
    )
  }
}

export default Signup
