import React from 'react'
import MaskedInput from 'react-text-mask'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Checkbox,
  Control,
  CustomInput,
  CustomSelect,
  Field as FormField,
  Label
} from 'sqrl-design-system'

import {
  PhoneMask,
  JobDescriptions,
  States,
  EmployeeCountCategory
} from './data'

import { signup as signupUrl } from '../../api'

import { jsonToURI } from '../../utils'

const phoneNumberMask = PhoneMask

const states = States

const jobDescriptions = JobDescriptions

const numEmployees = EmployeeCountCategory

const RenderCheckbox = ({ label, ...rest }) => {
  return (
    <Control {...rest}>
      <Label className="checkbox">
        <Checkbox type="checkbox" {...rest.field} />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </Label>
    </Control>
  )
}

const SignupSchema = Yup.object().shape({
  method: Yup.string()
    .required()
    .default('SQRL'),
  first_name: Yup.string()
    .required('Name is required')
    .min(2, 'Name too short')
    .max(40, 'Name too big'),
  last_name: Yup.string()
    .required('Last name is required')
    .min(2, 'Surname too short')
    .max(40, 'Surname too long'),
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  phone: Yup.string()
    .required('Phone is required')
    .length(14),
  employer: Yup.string()
    .required()
    .min(4, 'Employer name too small')
    .max(50, 'Employer name too big'),
  state: Yup.string().required('State is required'),
  job_description: Yup.string().required('Job desciption is required'),
  num_of_emp: Yup.string().required('Number of Employees is required'),
  agreement: Yup.bool()
    .oneOf([true], 'You must agree to T&C')
    .default(false)
})

const initialValues = SignupSchema.cast()

const SignupForm = () => (
  <div>
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        let final = {
          ...values
        }

        final.method = 'SQRL'

        fetch(signupUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            version: '1.0'
          },
          body: jsonToURI({ 'form-name': 'signup', ...final })
        })
          .then(response => {
            setSubmitting(false)

            if (response.status >= 400) {
              throw new Error('Bad response from server')
            }
            return response.json()
          })
          .then(result => {
            console.log('Signup result: ', result)
            alert('Success!')
          })
          .catch(error => alert(error))
      }}
      render={props => (
        <form
          className="form-signup"
          name="signup"
          onSubmit={props.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="signup" />

          <div className="divider-2">
            <Field
              className={
                props.touched.first_name && props.errors.first_name
                  ? 'is-invalid'
                  : ''
              }
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.first_name}
              id="first_name"
              name="first_name"
              label="First Name"
              component={CustomInput}
            />

            <Field
              className={
                props.touched.last_name && props.errors.last_name
                  ? 'is-invalid'
                  : ''
              }
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.last_name}
              id="last_name"
              name="last_name"
              label="Last Name"
              component={CustomInput}
            />
          </div>

          <Field
            className={
              props.touched.email && props.errors.email ? 'is-invalid' : ''
            }
            type="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            id="email"
            name="email"
            label="Workplace Email"
            component={CustomInput}
          />

          <Field
            type="text"
            name="phone"
            render={({ field }) => (
              <FormField>
                <MaskedInput
                  className={
                    props.touched.phone && props.errors.phone
                      ? 'is-invalid'
                      : ''
                  }
                  {...field}
                  render={(ref, props) => (
                    <CustomInput {...field} {...props} innerRef={ref} />
                  )}
                  mask={phoneNumberMask}
                  guide={false}
                  id="phone"
                  label="Company Phone"
                  type="text"
                />
              </FormField>
            )}
          />

          <div className="divider-2">
            <Field
              className={
                props.touched.employer && props.errors.employer
                  ? 'is-invalid'
                  : ''
              }
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.employer}
              id="employer"
              name="employer"
              label="Employer"
              component={CustomInput}
            />

            <FormField>
              <CustomSelect
                label="State"
                name="state"
                value={props.values.state}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                <option value="" label="" />
                {Object.keys(states).map(key => (
                  <option value={states[key]} label={states[key]} key={key} />
                ))}
              </CustomSelect>
            </FormField>
          </div>

          <FormField>
            <CustomSelect
              className={
                props.touched.job_description && props.errors.job_description
                  ? 'is-invalid'
                  : ''
              }
              name="job_description"
              label="Job Description"
              value={props.values.job_description}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            >
              <option value="" label="" />
              {jobDescriptions.map((s, i) => (
                <option value={s} label={s} key={`JobDesc${i.toString()}`} />
              ))}
            </CustomSelect>
          </FormField>

          <FormField>
            <CustomSelect
              className={
                props.touched.num_of_emp && props.errors.num_of_emp
                  ? 'is-invalid'
                  : ''
              }
              label="Number of Employees"
              name="num_of_emp"
              value={props.values.num_of_emp}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            >
              <option value="" label="" />
              {numEmployees.map((cat, i) => (
                <option value={cat} label={cat} key={`EmpCat${i.toString()}`} />
              ))}
            </CustomSelect>
          </FormField>

          <div className="agreement-container">
            <Field
              component={RenderCheckbox}
              type="checkbox"
              name="agreement"
              id="agreement"
            />
            <span>
              By creating an account, you agree to our SQRL{' '}
              <a href="/terms-of-use/">Terms of Use</a> and{' '}
              <a href="/privacy-policy/">Privacy Policy</a>, as well as our
              partner Dwolla’s{' '}
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.dwolla.com/legal/tos/"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.dwolla.com/legal/privacy/"
              >
                Privacy Policy
              </a>
              .
            </span>
          </div>

          <Button
            isColor={props.isValid ? 'primary' : ''}
            className="is-rounded local-auth"
            type="submit"
          >
            Try 1 Month Free
          </Button>
        </form>
      )}
    />
  </div>
)

export default SignupForm
